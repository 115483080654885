import React, { useState, useEffect } from "react";
import axios from "axios";
import { css } from "@emotion/react";
import { Container, Row, Col, Card, CardHeader, CardTitle, CardBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Table, Breadcrumb, BreadcrumbItem,Input } from "reactstrap";
import { RingLoader } from "react-spinners";
import { MoreVertical } from "react-feather";
import Select from "react-select";
import { parse } from "json2csv";
import { Link } from "react-router-dom";
import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
const override = css`
  display: block;
  margin: 0 auto;
`;

const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [quote, setQuote] = useState("");
  const [quoteLoading, setQuoteLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [industries, setIndustries] = useState([]);


  // Fetch categories from the endpoint
  useEffect(() => {
    axios.get("https://api2.fairs.as/24so/soap/companyCategoriesFrontend")
      .then(response => {
        const categoryOptions = response.data.Category.map(cat => ({
          value: cat.Id,
          label: cat.Name
        }));
        setCategories(categoryOptions);
      })
      .catch(error => console.error("Error fetching categories:", error));
  }, []);

  // Fetch quote
  useEffect(() => {
    axios.get("https://api2.fairs.as/24so/soap/quotes")
      .then(response => {
        const { quoteText, quoteAuthor } = response.data;
        setQuote(`${quoteText} — ${quoteAuthor || "Unknown"}`);
      })
      .catch(error => console.error("Error fetching quote:", error))
      .finally(() => setQuoteLoading(false));
  }, []);

  useEffect(() => {
    axios.get("https://api2.fairs.as/24so/soap/industriesFrontend")
      .then(response => {
        const industryOptions = Object.entries(response.data).map(([id, name]) => ({
          value: id,
          label: name,
        }));
        setIndustries(industryOptions);
      })
      .catch(error => console.error("Error fetching industries:", error));
  }, []);
  

  const fetchCustomers = async () => {
    setLoading(true);
    const filterIds = selectedCategories.map(cat => cat.value).join(',');
    const industryIds = selectedIndustries.map(industry => industry.value).join(',');
    try {
      const response = await axios.get(`https://api2.fairs.as/24so/soap/crmCompany?allow=9393931A&filter=${filterIds}&filterIndustry=${industryIds}`);
      //const response = await axios.get(`http://localhost:3002/24so/soap/crmCompany?allow=9393931A&filter=${filterIds}`);
      const customerData = response.data.map((customer) => ({
        ID: customer[0],
        RelationID: customer[1],
        Name: customer[2],
        "Organization Number": customer[3] || "",
        Category: customer[4] || "",
        IndustryId: customer[5] || "",
        "Contact Name": customer[6] || "",
        "Work Email": customer[7] || "",
        "Relation Email (Work)": customer[8] || "",
        "Relation Email (Invoice)": customer[9] || "",
        "Mobile Phone": customer[10] || "",
        Owner: customer[11] || "",
        "External ID": customer[12] || "",
        Street: customer[13] || "",
        Postal: customer[14] || "",
        City: customer[15] || "",
        Country: customer[16] || "",
      }));
      setCustomers(customerData);
    } catch (error) {
      console.error("Error fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  const handleExportCSV = () => {
    const timestamp = Date.now(); // Get the current timestamp
    const csv = parse(customers);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `customers_${timestamp}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const displayedCustomers = customers.slice(-10);

  return (
    <>
      <Container fluid>
        <Header>
          <HeaderTitle>Customers Data</HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Reports</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Customers</BreadcrumbItem>
          </Breadcrumb>
        </Header>

        <Row>
          <Col>
            <Card>
              <CardHeader>
                <CardTitle tag="h5" className="mb-0">Action</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={6}>
                    <div className="my-3">
                      <p>Category filter</p>
                      <Select
                        isMulti
                        isClearable
                        placeholder="Categories"
                        closeMenuOnSelect={false}
                        options={categories}
                        value={selectedCategories}
                        onChange={setSelectedCategories}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="my-3">
                      <p>Industry Filter</p>
                      <Select
                        isMulti
                        isClearable
                        placeholder="Select Industries"
                        closeMenuOnSelect={false}
                        options={industries}
                        value={selectedIndustries}
                        onChange={setSelectedIndustries}
                      />
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="my-3"></div>
                    <button onClick={fetchCustomers} className="btn btn-primary">Fetch Customers</button>
                    <div className="my-3"></div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      {loading ? (
        <>
          <div className="loading-screen">
            <RingLoader css={override} size={100} color={"#123abc"} loading={loading} />
            <p>Processing, Please Wait...</p>
          </div>
          <div>
            {quoteLoading ? (
              <p>Fetching quote...</p>
            ) : (
              <blockquote>
                <div className="loading-screen">
                  <p>{quote}</p>
                </div>
              </blockquote>
            )}
          </div>
        </>
      ) : (
        customers.length > 0 && (
          <Container fluid>
            <Row>
              <Col>
                <Card>
                  <CardHeader>
                    <div className="card-actions float-right">
                      <UncontrolledDropdown className="d-inline-block">
                        <DropdownToggle tag="a">
                          <MoreVertical />
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem onClick={handleExportCSV}>Export CSV</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                    <CardTitle tag="h5" className="mb-0">Customer Results ({ customers.length ? customers.length : 'Counting ...'})</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div style={{ maxHeight: "500px", overflowY: "auto" }}>
                      <div style={{ overflowX: "auto" }}>
                        <Table responsive hover>
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>RelationID</th>
                              <th>Name</th>
                              <th>Organization Number</th>
                              <th>Category</th>
                              <th>IndustryID</th>
                              <th>Contact Name</th>
                              <th>Work Email</th>
                              <th>Relation Email (Work)</th>
                              <th>Relation Email (Invoice)</th>
                              <th>Mobile Phone</th>
                              <th>Owner</th>
                              <th>External ID</th>
                              <th>Street</th>
                              <th>Postal</th>
                              <th>City</th>
                              <th>Country</th>
                            </tr>
                          </thead>
                          <tbody>
                            {displayedCustomers.map((customer, index) => (
                              <tr key={index}>
                                <td>{customer.ID}</td>
                                <td>{customer.RelationID}</td>
                                <td>{customer.Name}</td>
                                <td>{customer["Organization Number"]}</td>
                                <td>
                                  {customer.Category.length > 0 ? customer.Category.join(', ') : ''}
                                </td>
                                <td>{customer.IndustryId}</td>
                                <td>{customer["Contact Name"]}</td>
                                <td>{customer["Work Email"]}</td>
                                <td>{customer["Relation Email (Work)"]}</td>
                                <td>{customer["Relation Email (Invoice)"]}</td>
                                <td>{customer["Mobile Phone"]}</td>
                                <td>{customer.Owner}</td>
                                <td>{customer["External ID"]}</td>
                                <td>{customer.Street}</td>
                                <td>{customer.Postal}</td>
                                <td>{customer.City}</td>
                                <td>{customer.Country}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        )
      )}
    </>
  );
};

export default Customers;